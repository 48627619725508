<template>
  <div class="template-settings">
    <v-autocomplete
      :label="
        $localizationService.localize('drp_import.stepper.step.template.label')
      "
      :items="displayTemplates"
      :loading="loading"
      :disabled="pLoading"
      v-model="selectedTemplate"
      :placeholder="$localizationService.localize('common.begin_typing')"
      item-text="name"
      item-value="value"
      return-object
      clearable
      :filter="filterAutocomplete"
      ref="inputTemplate"
      :search-input.sync="searchTemplate"
      @change="onSelectTemplate()"
    >
      <template v-slot:selection="data">
        <ImportTemplate
          v-if="data.item != TEMPLATE_CREATION"
          :pTemplate="data.item"
          :pLoading="pLoading"
          @onSaveTemplate="saveTemplate($event)"
          @onDeleteTemplate="deleteTemplate($event)"
          @onDeactivateFocus="$refs.inputTemplate.isFocused = false"
        />
      </template>
      <template v-slot:no-data>
        <v-list-item-content class="pl-4">
          <ImportTemplate @onSaveTemplate="saveTemplate($event)" />
        </v-list-item-content>
      </template>
      <template v-slot:item="data">
        <ImportTemplate
          v-if="data.item == TEMPLATE_CREATION"
          @onSaveTemplate="saveTemplate($event)"
        />
        <ImportTemplate
          v-else
          :pTemplate="data.item"
          @onSaveTemplate="saveTemplate($event)"
          @onDeleteTemplate="deleteTemplate($event)"
        />
      </template>
    </v-autocomplete>
    <div v-if="selectedTemplate && selectedTemplate.id">
      <div v-if="targetTemplateType == RTL_TEMPLATE_TYPE">
        <v-btn
          v-if="!unitCreationInProgress"
          x-small
          class="mb-3"
          :disabled="pLoading"
          @click="addNewUnitLine()"
        >
          {{
            $localizationService.localize(
              "drp_import.stepper.step.template.rtl.new_unit"
            )
          }}
        </v-btn>
        <TemplateUnit
          v-show="unitCreationInProgress"
          :pLoading="pLoading"
          ref="unitCreationComponent"
          @onChangeUnit="changeUnit($event)"
          @onCloseEditMode="unitCreationInProgress = false"
        />
        <draggable
          v-model="selectedTemplate.lines"
          handle=".handle"
          @end="updateSelectedTemplate()"
        >
          <div v-for="(unit, index) of selectedTemplate.lines" :key="unit.id">
            <div
              v-if="unit.id"
              class="template-unit-line d-flex align-center w-100"
            >
              <v-btn icon class="handle" :disabled="pLoading">
                <v-icon> mdi-menu </v-icon>
              </v-btn>
              <TemplateUnit
                :pUnit="unit"
                :pLoading="pLoading || loading"
                :pUnitInEditMode="positionOfEditedUnitInArr == index"
                @onChangeUnitMode="onChangeUnitMode($event)"
                @onChangeUnit="changeUnit($event)"
                @onDeleteUnit="deleteUnit($event)"
              />
            </div>
          </div>
        </draggable>
      </div>
      <div v-if="targetTemplateType == XLSX_TEMPLATE_TYPE">
        <v-checkbox
          v-model="selectedTemplate.shouldReadOnlyVisibleRows"
          class="should-read-only-visible-rows"
          :label="
            $localizationService.localize(
              'drp_import.stepper.step.template.xlsx.param.read_only_visible_rows'
            )
          "
          @change="updateSelectedTemplate()"
          :disabled="pLoading"
        ></v-checkbox>
        <v-text-field
          v-model="selectedTemplate.rowsCountToSkipFromBegin"
          autofocus
          :label="
            $localizationService.localize(
              'drp_import.stepper.step.template.xlsx.param.skip_from_begin'
            )
          "
          @input="debouncedUpdateSelectedTemplate()"
          :disabled="pLoading"
        ></v-text-field>
        <v-text-field
          v-model="selectedTemplate.rowsCountToSkipFromEnd"
          autofocus
          :label="
            $localizationService.localize(
              'drp_import.stepper.step.template.xlsx.param.skip_from_end'
            )
          "
          @input="debouncedUpdateSelectedTemplate()"
          :disabled="pLoading"
        ></v-text-field>
        <v-text-field
          v-model="selectedTemplate.targetSheetName"
          autofocus
          :label="
            $localizationService.localize(
              'drp_import.stepper.step.template.xlsx.param.target_name'
            )
          "
          @input="debouncedUpdateSelectedTemplate()"
          :disabled="pLoading"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import debounce from "lodash/debounce";
import {
  RTL_TEMPLATE_TYPE,
  XLSX_TEMPLATE_TYPE,
  DRP_IMPORT_AMAZON_LOGISTICS,
  DRP_IMPORT_ROUTE_STAGING,
} from "../../../utils/utils";

const DEFAULT_AMAZON_LOGISTIC_TEMPLATE_NAME = "Amazon Logistics [default]";
const DEFAULT_ROUTE_STAGING_TEMPLATE_NAME = "Route Staging [default]";
const TEMPLATE_CREATION = {};

export default {
  name: "TemplateSettings",

  components: {
    draggable,
    ImportTemplate: () => import("./ImportTemplate.vue"),
    TemplateUnit: () => import("./TemplateUnit.vue"),
  },

  props: {
    pLoading: Boolean,
    pImportMode: String,
    pStoreModule: String,
  },

  watch: {
    searchTemplate() {
      if (this.searchTemplate) {
        this.loadFilteredTemplate();
      }
    },
  },

  data: () => ({
    TEMPLATE_CREATION,
    RTL_TEMPLATE_TYPE,
    XLSX_TEMPLATE_TYPE,

    searchTemplate: null,

    loading: false,
    selectedTemplate: undefined,
    templates: [],
    positionOfEditedUnitInArr: undefined,
    unitCreationInProgress: false,
  }),

  computed: {
    displayTemplates() {
      return [TEMPLATE_CREATION, ...this.templates];
    },

    // TODO: remove
    targetTemplateType() {
      if (this.pImportMode == DRP_IMPORT_AMAZON_LOGISTICS) { return RTL_TEMPLATE_TYPE; }
      if (this.pImportMode == DRP_IMPORT_ROUTE_STAGING) { return XLSX_TEMPLATE_TYPE; }
      return undefined;
    },
  },

  methods: {
    clearData() {
      this.selectedTemplate = undefined;
      this.templates = [];
      this.positionOfEditedUnitInArr = undefined;
      this.unitCreationInProgress = false;
    },

    onSelectTemplate() {
      if (this.selectedTemplate && this.selectedTemplate == TEMPLATE_CREATION) {
        this.selectedTemplate = undefined;
      }

      if (this.$refs.inputTemplate) this.$refs.inputTemplate.isFocused = false;

      this.emitSelectedTemplate();
    },

    emitSelectedTemplate() {
      if (this.selectedTemplate) {
        if (
          this.targetTemplateType == RTL_TEMPLATE_TYPE
          && !this.selectedTemplate.lines.length
        ) {
          this.$emit("onChangeTemplate", undefined);
          return;
        }

        this.$emit("onChangeTemplate", this.selectedTemplate);
      } else {
        this.$emit("onChangeTemplate", undefined);
      }
    },

    async saveTemplate(template) {
      if (template.id) {
        const updatedTemplate = await this.$store.dispatch(
          `${this.pStoreModule}/updateTemplate`,
          template,
        );

        const templateInd = this.templates.findIndex(
          (it) => it.id == template.id,
        );
        this.templates.splice(templateInd, 1, updatedTemplate);

        if (this.selectedTemplate && this.selectedTemplate.id == template.id) {
          this.selectedTemplate = updatedTemplate;
          this.emitSelectedTemplate();
        }
      } else {
        const newTemplate = await this.$store.dispatch(
          `${this.pStoreModule}/addNewTemplate`,
          template,
        );

        this.templates.push(newTemplate);

        this.selectedTemplate = newTemplate;
        this.onSelectTemplate();
      }
    },

    async onChangeUnitMode(unit) {
      if (unit) {
        const newUnitIndex = this.selectedTemplate.lines.findIndex(
          (it) => it.id == unit.id,
        );

        if (this.positionOfEditedUnitInArr !== undefined) {
          this.positionOfEditedUnitInArr = newUnitIndex;

          await this.changeUnit(unit);
        } else {
          this.positionOfEditedUnitInArr = newUnitIndex;
        }
      } else {
        this.positionOfEditedUnitInArr = undefined;
      }
    },

    async updateSelectedTemplate() {
      this.loading = true;

      await this.saveTemplate(this.selectedTemplate);

      this.emitSelectedTemplate();
      this.loading = false;
    },

    debouncedUpdateSelectedTemplate: debounce(async function () {
      await this.updateSelectedTemplate();
    }, 300),

    async deleteTemplate(template) {
      const index = this.templates.findIndex((it) => it.id == template.id);
      this.templates.splice(index, 1);

      await this.$store.dispatch(
        `${this.pStoreModule}/deleteTemplate`,
        template,
      );

      if (this.selectedTemplate && this.selectedTemplate.id == template.id) {
        this.selectedTemplate = undefined;
        this.emitSelectedTemplate();
      }
    },

    async changeUnit(unit) {
      if (unit.id) {
        const unitIndex = this.selectedTemplate.lines.findIndex(
          (line) => line.id == unit.id,
        );
        this.selectedTemplate.lines.splice(unitIndex, 1, unit);
      } else {
        this.selectedTemplate.lines.push(unit);
      }

      await this.updateSelectedTemplate();
    },

    deleteUnit(unit) {
      const unitIndex = this.selectedTemplate.lines.findIndex(
        (line) => line.id == unit.id,
      );
      this.selectedTemplate.lines.splice(unitIndex, 1);

      this.updateSelectedTemplate();
    },

    addNewUnitLine() {
      this.$refs.unitCreationComponent.createNewUnit();
      this.unitCreationInProgress = true;
    },

    filterAutocomplete(item, queryText) {
      if (item == TEMPLATE_CREATION) return item;

      if (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase())
        > -1
      ) { return item; }

      return undefined;
    },

    // TODO: warning
    loadFilteredTemplate: debounce(function (init) {
      this.loading = true;

      this.$store.dispatch(
        `${this.pStoreModule}/loadFilteredTemplates`,
        this.searchTemplate,
      ).then((data) => {
        this.templates = data;
        this.loading = false;

        if (init) {
          this.selectedTemplate = this.templates.find(
            (it) => it.name == DEFAULT_AMAZON_LOGISTIC_TEMPLATE_NAME
                  || it.name == DEFAULT_ROUTE_STAGING_TEMPLATE_NAME,
          );
          this.onSelectTemplate();
        }
      });
    }, 300),

    selectDefault() {
      this.loadFilteredTemplate(true);
    },
  },

  mounted() {
    this.selectDefault();
  },
};
</script>

<style lang="scss">
.light-local-user-theme {
  .template-settings {
    .template-unit-line {
      background-color: white !important;
    }
  }
}
.dark-local-user-theme {
  .template-settings {
    .template-unit-line {
      background-color: map-get($material-dark, "bg-color") !important;
    }
  }
}
</style>
